<template>
  <div class="myWallet">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">我的钱包</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="userInfo">
        <div class="avatarBox">
          <ImgDecypt class="avatar" :src="userInfo.portrait" round />
          <Vlogo :vid="userInfo.uid" class="vlogo" v-if="userInfo.merchantUser == 1" />
          <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="userInfo.merchantUser == 1" />
        </div>
        <div class="user">
          <div class="name" :class="{ vipName: userInfo.isVip && userInfo.vipLevel > 0 }">
            {{ userInfo.name }}
          </div>
          <div class="vipInfo" v-if="userInfo.isVip">
            {{ userInfo.vipExpireDate | time }}
          </div>
          <div class="noVipInfo" v-else>你当前未开通会员</div>
        </div>
      </div>
      <div class="walletBox">
        <div class="sameBox">
          <div class="title">我的钱包</div>
          <div class="desc">余额（元）</div>
          <div class="amount">{{ walletInfo.amount || 0 }}</div>
          <router-link tag="div" to="/billDetails" class="btn">账单明细</router-link>
        </div>
        <div class="verticalLine"></div>
        <div class="sameBox">
          <div class="desc">累计收益（元）</div>
          <div class="amount">
            {{ (walletInfo.income || 0) + (walletInfo.incomePot || 0) }}
          </div>
          <router-link tag="div" to="/incomeCenter" class="btn">收益中心</router-link>
        </div>
      </div>
      <div class="subTitle">在线充值</div>
      <div class="goldCoinMain">
        <div
          class="goldCoinBox"
          v-for="(item, index) in amountList"
          :key="item.id"
          :class="selectIndex === index ? 'goldCoinBoxActive' : ''"
          @click="selectAmount(index)"
        >
          <div class="rmb">
            <span>¥</span>
            {{ item.money ? item.money / 100 : 0 }}
          </div>
          <div class="goldCoin">
            <svg-icon class="gold" iconClass="gold" />
            {{ item.amount || 0 }}
          </div>
          <div class="gift" v-if="item.firstGiveGold > 0">首充送{{ item.firstGiveGold }}金币</div>
        </div>
      </div>
      <div class="btnBox">
        <router-link tag="div" class="conCusService" to="/kefu">
          <svg-icon class="cusService" iconClass="cusService" />
          联系客服
        </router-link>
        <div class="openNow" @click="showPay(true)">立即充值</div>
      </div>
    </div>
    <RechargeBox ref="rechargeBox" :isRecharge="isRecharge" @closePay="showPay" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ImgDecypt from '@/components/ImgDecypt';
import Vlogo from '@/components/Vlogo';
import RechargeBox from '@/components/RechargeBox';
import { queryCurrencys } from '@/api/mine';
import { Toast } from 'vant';
export default {
  name: 'MyWallet',
  components: {
    ImgDecypt,
    RechargeBox,
    Vlogo,
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      walletInfo: 'walletInfo',
    }),
  },
  data() {
    return {
      selectIndex: 0, // 选中下标
      amountList: [], // 金额列表
      isRecharge: false, // 充值界面
      graphicsId: '',
      graphicsTitle: '',
    };
  },
  created() {
    this.$store.dispatch('user/getWallet');
    this.queryCurrencys();
  },
  methods: {
    // 查询金额
    async queryCurrencys() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let ret = await this.$Api(queryCurrencys, {
          type: '1',
        });
        if (ret && ret.code === 200) {
          this.amountList = ret.data.list;
          this.changItem();
          this.dcInfo = JSON.parse(JSON.stringify(ret.data.daichong));
        } else if (ret.tip) {
          Toast(ret.tip);
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('查询失败，请稍后再试');
      }
    },
    // 选择金额
    selectAmount(index) {
      this.selectIndex = index;
      this.payMethods = [...this.amountList[index].rchgType];
      this.changItem();
    },
    // 选择金币
    changItem() {
      let payData = {
        rechargeList: [...this.amountList[this.selectIndex].rchgType], // 支付方式列表
        dcInfo: this.dcInfo, // 代充信息
        popType: 'gold', // 弹窗类型
        productType: undefined, // 商品类型
        productID: this.amountList[this.selectIndex].id, // 商品ID
        dcType: 0, // 代充商品类型 0-购买金币 1-是会员 2-是果币  3-是裸聊
        buyType: 1, // 商品类型  1-是金币 2-是游戏币 3-是果币 4-是商品
        discountedPrice: this.amountList[this.selectIndex].money / 100, // 充值金额
      };
      this.$refs.rechargeBox.changItem(payData);
    },
    showPay(flag) {
      this.isRecharge = flag;
    },
  },
  beforeRouteEnter(to, from, next) {
    //视频跳转过来
    if (from.name == 'CommunityVideo' || from.name == 'HorizontalVideo' || from.name == 'ShortVideo') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = vm.$store.getters.currentVideoObj['id'];
        vm.$refs.rechargeBox.graphicsTitle = vm.$store.getters.currentVideoObj['title'];
      });
    } else if (from.name == 'ComRecommend' || from.name == 'Announcement') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'banner';
      });
    } else if (from.name == 'TaskHall') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'signIn';
      });
    } else if (from.name == 'PostDetails') {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'exceptional';
      });
    } else {
      next((vm) => {
        vm.$refs.rechargeBox.graphicsId = 'user';
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.myWallet {
  height: 100%;
  background-color: #fff;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 14px;
    .userInfo {
      display: flex;
      align-items: center;
      padding: 10px 0 5px;
      .avatarBox {
        position: relative;
        .avatar {
          height: 52px;
          width: 52px;
          background-color: #000;
          border-radius: 50%;
          margin-right: 6px;
        }
        .vlogo {
          width: 18px;
          height: 18px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .certificaBorder {
          position: absolute;
          top: -10px;
          left: -3px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }
      .user {
        font-size: 14px;
        .name {
          font-size: 18px;
        }
        .vipName {
          color: #ff678f;
        }
        .vipInfo {
          color: #ff678f;
        }
        .noVipInfo {
          color: rgb(153, 153, 153);
        }
      }
    }
    .walletBox {
      height: 141px;
      padding: 7px 20px;
      margin-top: 10px;
      box-sizing: border-box;
      background-color: rgb(249, 229, 236);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sameBox {
        flex: 1;
        .title {
          font-size: 16px;
          color: rgb(49, 46, 44);
        }
        .desc {
          font-size: 12px;
          color: rgb(111, 53, 67);
          margin-top: 10px;
        }
        .amount {
          font-size: 22px;
        }
        .btn {
          height: 28px;
          width: 88px;
          background-color: #fff;
          border-radius: 4px;
          text-align: center;
          line-height: 28px;
          font-size: 14px;
          color: rgb(99, 55, 76);
          margin: 10px 0;
        }
      }
      .sameBox:last-of-type {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
      }
      .verticalLine {
        height: 82px;
        width: 1px;
        background-image: linear-gradient(rgb(255, 215, 234), rgb(255, 176, 214), rgb(255, 215, 234));
      }
    }
    .subTitle {
      font-size: 18px;
      margin: 20px 0 15px;
    }
    .goldCoinMain {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 10px;
      margin-bottom: 70px;
      .goldCoinBox {
        height: 80px;
        width: 110px;
        border-radius: 6px;
        border: 1px solid rgb(242, 242, 242);
        padding: 3px 6px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .rmb {
          font-size: 13px;
          span {
            font-size: 10px;
          }
        }
        .goldCoin {
          font-size: 18px;
          font-weight: 800;
          margin: 2px 0;
          .gold {
            height: 20px;
            width: 20px;
          }
        }
        .gift {
          height: 22px;
          width: 96px;
          font-size: 12px;
          text-align: center;
          line-height: 22px;
          color: rgb(153, 153, 153);
          border-radius: 4px;
          background-color: rgb(248, 248, 248);
        }
      }
      .goldCoinBoxActive {
        border: 2.5px solid rgb(255, 103, 143);
        .goldCoin {
          color: rgb(255, 103, 143);
        }
        .gift {
          color: #fff;
          background-color: rgb(255, 103, 143);
        }
      }
    }
    .btnBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      .conCusService {
        height: 40px;
        width: 170px;
        background-color: rgb(246, 246, 246);
        border: 2px solid rgb(102, 102, 102);
        box-sizing: border-box;
        border-radius: 6px;
        text-align: center;
        font-size: 14px;
        color: rgb(102, 102, 102);
        display: flex;
        justify-content: center;
        align-items: center;
        .cusService {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
      .openNow {
        height: 40px;
        width: 170px;
        background-color: rgb(255, 103, 143);
        border-radius: 6px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}
</style>
